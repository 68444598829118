.icon-buckets {
    margin: 5px;
    border: 1px dashed black;
    padding: 5px;
}

.icon-bucket {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.icon-container {
    width: 40px;
}

.icon-occurences-title {
    margin-top: 0px;
    margin-bottom: 5px;
}

.sum-error{
    color: hsl(0, 75%, 50%);
    font-weight: bold;
}