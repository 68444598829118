.color-pin-container {
  position: relative;
}

.colorPin {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border-width: 2px;
    border-color: black;
    border-style: solid;
    margin: 5px;
}

.disabled-color {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='black' stroke-width='17'/><path d='M0 0 L100 100 ' stroke='black' stroke-width='17'/></svg>");
  background-repeat:no-repeat;
  background-position:center center;
  background-size: 100% 100%, auto;
  cursor:alias
}

.highlighted-color {
  border-width: 0px;
  padding: 2px;
  box-shadow: 0px 0px 10px 5px rgba(255,255,0,1);
}

.opaque-color {
  opacity: 0.15;
}

.pick-up {
  animation: pick-up 0.5s linear 0s 1 normal;
}

@keyframes pick-up {
  0%{
    transform: scale(1);
  }
  20%{
    transform: scale(1.25);
  }
  40%{
    transform: scale(1.5);
    opacity: 0.75;
  }
  60%{
    transform: scale(1.75);
    opacity: 0.50;
  }
  80%{
    transform: scale(2);
    opacity: 0.25;
  }
  100%{
    transform: scale(2.25);
    opacity: 0;
  }
}

.drop-down {
  animation: drop-down 0.5s linear 0s 1 normal;
}

@keyframes drop-down {
  0%{
    transform: scale(2.25);
    opacity: 0;
  }
  20%{
    transform: scale(2);
    opacity: 0.25;
  }
  40%{
    transform: scale(1.75);
    opacity: 0.50;
  }
  60%{
    transform: scale(1.5);
    opacity: 0.75;
  }
  80%{
    transform: scale(1.25);
    opacity: 1;
  }
  100%{
    transform: scale(1);
  }
}

.colorPin.discard {
  animation: discard 0.5s linear 0s 1 normal;
}

@keyframes discard {
  0%{
    transform: scale(1);
    opacity: 1;
  }
  20%{
    transform: scale(0.8);
    opacity: 0.8;
  }
  40%{
    transform: scale(0.6);
    opacity: 0.6;
  }
  60%{
    transform: scale(0.4);
    opacity: 0.4;
  }
  80%{
    transform: scale(0.2);
    opacity: 0.2;
  }
  100%{
    transform: scale(0);
    opacity: 0;
  }
}