.iconPin {
    margin: 5px;
    border-radius: 50%;
    filter: 
        drop-shadow(-1px -1px 0px hsla(0, 100%, 100%, 0.75)) 
        drop-shadow(2px -1px 0px hsla(0, 100%, 100%, 0.75)) 
        drop-shadow(2px 2px 0px hsla(0, 100%, 100%, 0.75))
        drop-shadow(-1px 2px 0px hsla(0, 100%, 100%, 0.75))
}

.disabled-icon {
    color: grey;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='grey' stroke-width='17'/><path d='M0 0 L100 100 ' stroke='grey' stroke-width='17'/></svg>");
    background-repeat:no-repeat;
    background-position:center center;
    background-size: 100% 100%, auto;
    cursor:alias;
    filter:none;
  }
  
  .highlighted-icon {
    border-width: 0px;
    padding: 0px;
    box-shadow: 0px 0px 10px 5px rgba(255,255,0,1);
  }

  .transparent-icon {
    opacity: 0.5;
  }
  
  .opaque-icon {
    opacity: 0.15;
  }

  .iconPin.discard {
    animation: discard 0.5s linear 0s 1 normal;
  }
  
  @keyframes discard {
    0%{
      transform: scale(1);
      opacity: 1;
    }
    20%{
      transform: scale(0.8);
      opacity: 0.8;
    }
    40%{
      transform: scale(0.6);
      opacity: 0.6;
    }
    60%{
      transform: scale(0.4);
      opacity: 0.4;
    }
    80%{
      transform: scale(0.2);
      opacity: 0.2;
    }
    100%{
      transform: scale(0);
      opacity: 0;
    }
  }