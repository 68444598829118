.combination-notes-container {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}

.combination-notes-title {
    margin-top: 10px;
    margin-bottom: 5px;
}

.add-color-tuple-button {
    height: 36px;
    width: 36px;
    background-color: hsl(120, 100%, 60%);
    margin: auto;
    margin-top: 5px;
    opacity: 30%;
}

.add-color-tuple-button:hover {
    opacity: 100%;;
}