.legend {
    border: 1px solid black;
    padding: 5px;
}

.legend-list{
    display: flex;
    flex-direction: column;
}

.legend-list-item {
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.legend-intro {
    text-align: left;
}