.color-buckets {
    margin: 5px;
    border: 1px dashed black;
    padding: 5px;
}

.color-bucket {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.color-occurences-title {
    margin-top: 0px;
    margin-bottom: 5px;
}

.sum-error{
    color: hsl(0, 75%, 50%);
    font-weight: bold;
}