.slot-hints-outer-container {
    display: flex;
    flex-direction: column;
    padding-left: 23px;
}

.slot-hints-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.slot-hints-title {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
}
