.selection-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.selection-box{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border: 2px solid black;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 2px; 
}

.selection-text {
    font-weight: bold;
    font-size: large;
}