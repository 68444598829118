.game-row {
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid black;
    padding: 5px;
}

.game-row.highlighted {
    background-color: hsl(34,79%,70%);
}

.row-index-container {
    width: 18px;
}