.game-row-buttons {

}

.info-pins-container {
    height: 36px;
}

.info-pins {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-end;
    height: 36px;
}

.info-pin {
    height: 10px;
    min-height: 6px;
    width: 10px;
    min-width: 6px;
    border-radius: 50%;
    border-width: 1px;
    border-color: black;
    border-style: solid;
    margin: 3px;
}

.random-button {
    height: 36px;
    width: 36px;
    margin-right: 5px;
}

.submit-button {
    height: 36px;
    width: 36px;
}

.hole {
    height: 10px;
    min-height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: hsl(34, 57%, 50%);
}

.black {
    height: 10px;
    min-height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: black;
}

.white {
    height: 10px;
    min-height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: white;
}
.grey {
    height: 10px;
    min-height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: grey;
}

.black-white {
    height: 6px;
    width: 6px;
    background-color: white;
    border-width: 3px;
    border-color: black;
}

.black-grey {
    height: 6px;
    width: 6px;
    background-color: grey;
    border-width: 3px;
    border-color: black;
}

.grey-black {
    height: 6px;
    width: 6px;
    background-color: black;
    border-width: 3px;
    border-color: grey;
}

.white-black {
    height: 6px;
    width: 6px;
    background-color: black;
    border-width: 3px;
    border-color: white;
}

.white-core {
    height: 6px;
    width: 6px;
    background-color: white;
    border-width: 3px;
    border-color: hsl(34, 57%, 50%);
}

.black-core {
    height: 6px;
    width: 6px;
    background-color: black;
    border-width: 3px;
    border-color: hsl(34, 57%, 50%);
}

.white-rim {
    height: 6px;
    width: 6px;
    background-color: hsl(34, 57%, 50%);
    border-width: 3px;
    border-color: white;
}

.black-rim {
    height: 6px;
    width: 6px;
    background-color: hsl(34, 57%, 50%);
    border-width: 3px;
    border-color: black;
}