.combination-notes-row{
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    padding: 5px;
}

.tuple-row-note {
    background-color: hsl(34, 57%, 70%);
}

.add-tuple-slot-button {
    display: inline-flex;
    height: 24px;
    width: 24px;
    background-color: hsl(120, 100%, 60%);
    margin: 5px;
    opacity: 30%;
}

.delete-tuple-row-button {
    display: inline-flex;
    height: 24px;
    width: 24px;
    background-color: hsl(0, 100%, 60%);
    margin: 5px;
    opacity: 30%;
}

.add-tuple-slot-button:hover, .delete-tuple-row-button:hover{
    opacity: 100%;
}