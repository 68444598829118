.game {
    display: flex;
    width: fit-content;
    flex-direction: row;
    margin: 0 auto;
    align-items: start;
    justify-content: flex-start;
}

.board {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sidePanel {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}