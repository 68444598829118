.color-selector {
    border: 1px solid black;
    background-color: hsla(34, 57%, 70%, 0.7);
    position: absolute;
    left: 36px;
    bottom: 36px;
    z-index: 1000;
}

.color-selector-close-button {
    width: 30px;
    height: 30px;
    font-weight: bold;
    cursor: pointer
}

.color-selector-close-button:hover {
    cursor: pointer
}