.boolean-settings-label {
    text-align: left;
    width: 200px;
}

.settings-table {
    display: flex;
    flex-direction: column;
    width: 350px;
    margin: 10px auto;
}

.settings-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.settings-title {
    margin-top: 15px;
    margin-bottom: 0px;
}

.settings-paragraph {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 10px;
}

input, select, option {
    background-color: hsl(34, 40%, 75%);
    border-radius: 5px;
}