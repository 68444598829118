.slot-hint-column{
    width: 39px;
    display: flex;
    flex-direction: column;
}

.slot-hint-column.no-selection-error {
    border: 2px solid hsl(0, 50%, 50%);
    border-radius: 20px;
    margin: -2px;
}